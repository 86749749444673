import { Auth0Client } from "@auth0/auth0-spa-js";

const _auth0Client = new Auth0Client({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  client_id: process.env.VUE_APP_AUTH0_CLIENTID,
  audience: process.env.VUE_APP_API_AUDIENCE,
});

const authLib = {
  async doLogin() {
    const redir_base = process.env.VUE_APP_BASE_URL;
    const redir_path = process.env.VUE_APP_LOGIN_REDIR;
    console.log(`${redir_base}${redir_path}`);
    await _auth0Client.loginWithRedirect({
      redirect_uri: `${redir_base}${redir_path}`,
    });
  },

  ///
  /// returns true if this is the users first login
  ///
  async chkLogin(api, store) {
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await _auth0Client.handleRedirectCallback();
      const token = await _auth0Client.getTokenSilently();

      const response = await api
        .authApi()
        .extn_auth(token);
      store.dispatch('login', {
        userId: response.data.userId,
        token: response.data.token,
        userType: response.data.usertype,
      });
      store.dispatch('updateFullname', response.data);
      api.setAuth(response.data.token);
      return response.data;
    }
  },

  async getApiToken() {
    const token = await _auth0Client.getTokenSilently();
    return token;
  },

  async doLogout() {
    await _auth0Client.logout({ returnTo: process.env.VUE_APP_BASE_URL });
  }
};

export default authLib;
